<template>
  <div>
    <show-top-agent-self-subscribe-order v-if="this.$store.getters.userRole === 'top_agent'" />
    <show-agency-self-subscribe-order v-else-if="this.$store.getters.userRole === 'agency'" />
    <show-merchant-self-subscribe-order v-else-if="this.$store.getters.userRole === 'merchant'" />
  </div>
</template>

<script>

export default {
  name: 'ShowSubscribeOrder',
  components: {
    ShowTopAgentSelfSubscribeOrder: () => import('@/views/agent_monthly_bills_commission/subscribe/top_agent_self/Show'),
    ShowAgencySelfSubscribeOrder: () => import('@/views/agent_monthly_bills_commission/subscribe/merchant_self/Show'),
    ShowMerchantSelfSubscribeOrder: () => import('@/views/agent_monthly_bills_commission/subscribe/merchant_self/Show')
  }
}
</script>
